body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiCircularProgress-svg {
  width: unset !important;
  height: unset !important;
}
.selected-button {
  border-radius: 70px;
  background: linear-gradient(
    94deg,
    #1f5aff -32.1%,
    #392ed6 52.24%,
    #1a2032 136.58%
  );
  color: #fff;
}

.unselected-button {
  border-radius: 70px;
  background: #fff;
  color: #000;
}

/* Add this CSS to your style.css or style.js file */
.button-container {
  display: flex;
  overflow-x: auto;
  /* Enable horizontal scrolling */
  gap: 20px;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  /* Set a fixed width or max-width to the container */
  padding-bottom: 20px;
  /* Add padding at the bottom to ensure buttons don't get cut off */
  white-space: nowrap;
  /* Prevent buttons from wrapping to the next line */
}

.css-1c0kj6m-MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

@keyframes infiniteScroll {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}
@keyframes infiniteScroll2 {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.wrapper {
  max-height: 120px;
  /* border: 1px solid #ddd; */
  display: flex;
  overflow-x: scroll;
  gap: 30px;
  transform: translateX();
}

.wrapper::-webkit-scrollbar {
  width: 0;
}

.wrapper .item {
  animation: infiniteScroll 7s linear infinite;
  min-width: 392px;
  height: 62px;
  /* text-align: center; */
  border-radius: 52px;
  /* line-height: 110px; */
  background: #fff;
}

.wrapper2 {
  max-height: 120px;
  /* border: 1px solid #ddd; */
  display: flex;
  overflow-x: scroll;
  gap: 30px;
  transform: translateX();
}

.wrapper2::-webkit-scrollbar {
  width: 0;
}

.wrapper2 .item2 {
  animation: infiniteScroll2 8s linear infinite;
  min-width: 450px;
  height: 62px;
  border-radius: 52px;
  background: #fff;
}

.wrapper .item3 {
  animation: infiniteScroll 7s linear infinite;
  min-width: 450px;
  height: 62px;
  border-radius: 52px;
  background: #fff;
}

.card__wrapper {
  margin-top: 60px;
  display: flex;
  overflow-x: scroll;
  gap: 30px;
}

.wrapper::-webkit-scrollbar {
  width: 0;
}

.card__wrapper .card__item {
  animation: infiniteScroll 10s linear infinite;
  min-width: 348px;
  height: 230px;
  border-radius: 20px;
  background: #efefef;
  padding: 25px 10px 0px 25px;
}
/* CSS for MUI Tab Component */

/* Tab indicator styles */
.css-1aquho2-MuiTabs-indicator {
  height: 3px; /* Adjust the indicator height as needed */
  background-color: #007acc; /* Set the desired indicator color */
  border-radius: 0; /* Remove border-radius if not needed */
}

/* Selected tab styles */
.Mui-selected {
  color: #fff !important; /* Text color for selected tab */
  /* background-color: #007acc; */
  background: #392ed6 !important;
  border-top-left-radius: 20px !important; /* Background color for selected tab */
  border-top-right-radius: 20px !important; /* Background color for selected tab */
}

/* Unselected tab styles */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #000; /* Text color for unselected tabs */
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500; /* Adjust font weight if needed */
  line-height: 1.2; /* Adjust line height if needed */
  max-width: 250px;
  padding: 12px 20px; /* Adjust padding as needed */
  margin: 0; /* Remove any default margin */
  border-radius: 0; /* Remove border-radius if not needed */
}

/* Optional hover effect */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
  /* Add a hover background color if desired */
  cursor: pointer; /* Add pointer cursor on hover .*/
}
.MuiContainer-root {
  max-width: 1290px !important;
}
@media (max-width: 600px) {
  @keyframes infiniteScroll2 {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-50%);
    }
  }
  .boxMui {
    flex-direction: column;
  }
  .MuiTabs-flexContainer {
    flex-direction: column;
  }
  .MuiButtonBase-root-MuiTab-root {
    max-width: 100%;
  }
  .css-1aquho2-MuiTabs-indicator {
    height: unset !important;
  }

  .card__item-h5 {
    font-size: 20px !important;
    text-align: center;
  }
  .card__item-p {
    font-size: 18px;
  }
  .displaypadding {
    flex-direction: column;
    gap: 20px;
  }
  .css-etnwv9-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
  }
  .consultation__img {
    width: 100% !important;
    height: 100% !important;
  }
  .MuiTabPanel-root {
    text-align: center;
  }
  .align__items {
    flex-direction: column;
  }
  .wrapper .item {
    animation: infiniteScroll 3s linear infinite;
  }
  .wrapper2 .item2 {
    animation: infiniteScroll2 2s linear infinite;
  }
  .wrapper .item3 {
    animation: infiniteScroll 3s linear infinite;
  }

  .card__wrapper {
    margin-top: 60px;
    display: flex;
    gap: 30px;
    overflow-x: unset;
    flex-direction: column;
  }
  .card__item-p{
    font-size: 20px !important;
    text-align: center;
  }
  .wrapper::-webkit-scrollbar {
    width: 0;
  }

  .card__wrapper .card__item {
    animation:  unset;
    min-width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #efefef;
    padding: 25px 25px 25px 25px;
  
  }
}
.marquee-container {
  height: 45px;
  overflow: hidden;
  line-height: 30px;
position: relative;
margin-bottom: 20px;
  .marquee {
    display: flex;
    gap: 30px;
    align-items: center;
    top: 0;

    left: 100%;
    /* width: 100%; */
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
  }

  .marquee2 {
    animation-delay: 15s;
  }
  b {
    padding-left: 10px;
  }
}

@keyframes marquee {
  0% {
    left: 5%;
  }
  100% {
    left: -260%
  }
  
}

  @media (max-width: 600px) {
    .card__wrapper .card__item{
min-width: 100%;

    }
    .Mui-selected{
      max-width: 100% !important;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      max-width: 100% !important;
    }
  }